<template>
  <div />
</template>

<script>
import companyVoice from './company-voiceProfile.js';

export default {
  name: 'VoiceSettings',

  mounted() {
    this.$el.append(companyVoice());
  },
};
</script>

<style></style>
