import { cr, ap, pp } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import advancedSelect from '@/lib/ui/advancedSelect';
import flag from '@/lib/ui/flag';
import textArea from '@/lib/ui/textArea';
import i from '@/lib/ui/i';
import button from '@/lib/ui/button';
import api from '@/util/api';
import is from '@/lib/util/is';
import getUserProfile from '@/util/getUserProfile';
import { PLAN_ENTERPRISE } from '@/consts/plans';
import { ucFirst } from '@/lib/util/capitalize';

// The audio format represented by the Base64 encoded string source.
const audioFormatEncoding = 'data:audio/mp3;base64,';
let audio = new Audio();

const languageSettings = () => {
  const rootElement = cr('section', 'c-conference-settings');

  const save = () => api.updateCustomerVoiceProfile(form).then(reloadData).catch(reloadData); // TODO: Handle error

  const playVoiceAudioSample = () => {
    if (!audio.paused) {
      audio.pause();
      return;
    }

    api
      .getVoiceAudioSample(
        form.voice,
        form.voiceIntro + ' ' + form.conferenceMessage + ' ' + form.conferenceJoin,
      )
      .then((result) => {
        audio = new Audio(audioFormatEncoding + result.audioSample);
        audio.play();
      })
      .catch(); // TODO: Handle error
  };

  const checkVoiceFeaturesEnabled = (plan) => {
    if (plan !== PLAN_ENTERPRISE) {
      [
        voiceField.searchFilter.inputElement,
        voiceIntroField.inputElement,
        conferenceMessageField.inputElement,
        conferenceJoinField.inputElement,
        saveButton,
      ].forEach((inputElement) => {
        inputElement.disabled = true;
      });

      pp(
        rootElement,
        ap(
          cr('div', 'c-level u-bg-yellow-light'),
          ap(
            cr('div', 'o-wrapper'),
            cr('h3', null, t('voiceDisabled')),
            cr(
              'p',
              null,
              t('voiceDisabledMsg', null, {
                requiredPlan: ucFirst(PLAN_ENTERPRISE),
                yourPlan: ucFirst(plan),
                mail: 'sales@teamalert.net',
              }),
            ),
          ),
        ),
      );
    }
  };

  const reloadData = () =>
    api.getCustomerVoiceProfile().then((res) => {
      const voiceProfile = res.profile;
      api.getVoices().then((res) => {
        const voiceList = res.voices;
        voiceList.sort((a, b) => a.languageName > b.languageName);
        voiceList.forEach((voice) => {
          voice.name = `${voice.languageName}, "${voice.id}"`;
        });
        voiceField.updateOptions(voiceList);
        if (is.strNotEmpty(voiceProfile.voiceId)) {
          form.voice = voiceProfile.voiceId;
          voiceField.setSelected(voiceProfile.voiceId);
        }
      }); // TODO: Handle error

      voiceProfile.messageIntroduction =
        voiceProfile.messageIntroduction || voiceProfile.messageIntrodction;
      if (is.strNotEmpty(voiceProfile.messageIntroduction)) {
        form.voiceIntro = voiceProfile.messageIntroduction;
        voiceIntroField.setValue(voiceProfile.messageIntroduction);
      } else {
        getUserProfile().then((profile) => {
          form.voiceIntro = t('companyDefaultVoiceIntro', null, {
            company: profile.customer.customerName,
          });
          voiceIntroField.setValue(form.voiceIntro);

          const plan = profile.customer.plan;
          checkVoiceFeaturesEnabled(plan);
        });
      }

      if (is.strNotEmpty(voiceProfile.messageCallConf)) {
        form.conferenceMessage = voiceProfile.messageCallConf;
        conferenceMessageField.setValue(voiceProfile.messageCallConf);
      }

      if (is.strNotEmpty(voiceProfile.messageKey1)) {
        form.conferenceJoin = voiceProfile.messageKey1;
        conferenceJoinField.setValue(voiceProfile.messageKey1);
      }
    }); // TODO: Handle error

  const form = {
    voice: null,
    voiceIntro: null,
    conferenceMessage: t('companyDefaultConferenceMessage'),
    conferenceJoin: t('companyDefaultConferenceJoin'),
  };

  const onChange = (event) => {
    const key = event.target.name;
    let value = event.target.value;

    if (is.object(value) && value.id) {
      value = value.id;
    }

    form[key] = value;
  };

  const voiceOptionRenderer = (voice, text) => {
    let code = voice.languageCode;
    code = code.substring(code.lastIndexOf('-') + 1).toLowerCase();
    return ap(cr(), flag(code), ' ', text);
  };

  const voiceField = advancedSelect(
    {
      labelText: t('voice'),
      helperText: t('voiceHelperText'),
      min: 1,
      onChange,
      optionRenderer: (voice, result) => voiceOptionRenderer(voice, result),
      selectedRenderer: (voice) => voiceOptionRenderer(voice, voice.name),
      noResultsTxt: t('noResults'),
    },
    {
      name: 'voice',
    },
  );

  const voiceIntroField = textArea(
    {
      labelText: t('voiceIntro'),
      helperText: t('voiceIntroHelperText'),
      onChange,
    },
    {
      name: 'voiceIntro',
      value: form.voiceIntro,
    },
  );

  const conferenceMessageField = textArea(
    {
      labelText: t('conferenceMessage'),
      helperText: t('conferenceMessageHelperText'),
      onChange,
    },
    {
      name: 'conferenceMessage',
      value: form.conferenceMessage,
    },
  );

  const conferenceJoinField = textArea(
    {
      labelText: t('conferenceJoin'),
      helperText: t('conferenceJoinHelperText'),
      onChange,
    },
    {
      name: 'conferenceJoin',
      value: form.conferenceJoin,
    },
  );

  const voiceSampleButton = button(playVoiceAudioSample, 'left ', t('sampleVoice'));
  const saveButton = button(save, 'float-right', t('save'));

  reloadData();

  return ap(
    rootElement,
    ap(
      cr('div', 'c-level u-bg-gray-100'),
      ap(
        cr('div', 'o-wrapper'),
        ap(cr('h3'), i('microphone', 'u-color-blue-dark'), ' ', t('voiceSettings')),
        voiceField.element,
        voiceIntroField.element,
      ),
    ),
    ap(
      cr('div', 'c-level'),
      ap(
        cr('div', 'o-wrapper'),
        ap(cr('h3'), i('phone', 'u-color-blue-dark'), ' ', t('conferenceSettings')),
        conferenceMessageField.element,
        conferenceJoinField.element,
      ),
    ),
    ap(cr('div', 'c-level'), ap(cr('div', 'o-wrapper'), voiceSampleButton, saveButton)),
  );
};

export default languageSettings;
